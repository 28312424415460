import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { request } from '../API/Request';
import { AppContext } from '../App';
import Notes from '../assets/notes.svg';
import DoctorBookingCard from '../components/DoctorBookingCard';
import ScheduleTable from '../components/ScheduleTable';
import DeleteConfirmation from '../components/DeleteConfirmation';

const DoctorBookings = () => {
  const { currentUser, setLoading, sm, xs } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [bookings, setBookings] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState('');
  const [descriptionDialog, setDescriptionDialog] = useState(false);
  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [description, setDescription] = useState('');
  const [patientName, setPatientName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [bookDialog, setBookDialog] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [customDate, setCustomDate] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(null);
  const [scheduleMode, setScheduleMode] = useState(
    JSON.parse(localStorage.getItem('scheduleMode')) || false
  );
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      schedules: days.map(() => ({
        startHour: '',
        endHour: '',
        interval: 15,
        off: false,
      })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'schedules',
  });

  useEffect(() => {
    const storedScheduleData = JSON.parse(localStorage.getItem('scheduleData'));
    console.log(storedScheduleData);
    if (storedScheduleData) {
      setValue(
        'schedules',
        storedScheduleData.map((day) => ({
          startHour: day.startHour,
          endHour: day.endHour,
          interval: day.interval,
          off: day.off,
        }))
      );
    }
  }, [setValue]);
  const schedules = useWatch({ control, name: 'schedules' });

  const getBookings = async () => {
    setLoading(true);
    const response = await request(
      `get-doctor-bookings/${currentUser?.doctor_id}`,
      'GET'
    );
    if (response.success) {
      setBookings(response.bookings);
    }
    setLoading(false);
  };
  useEffect(() => {
    document.title = 'Weqayah | Bookings';
    if (currentUser?.doctor_id) {
      getBookings();
    }
  }, [currentUser]);

  const getTabs = async () => {
    setLoading(true);
    const response = await request(`get-tabs/${currentUser?.doctor_id}`, 'GET');
    setTabs(response.data);
    setLoading(false);
  };

  const next = () => {
    if (sm) setPageIndex(pageIndex + 5);
    else if (xs) setPageIndex(pageIndex + 4);
    else setPageIndex(pageIndex + 3);
  };

  const prev = () => {
    if (sm) {
      if (pageIndex > 4) {
        setPageIndex(pageIndex - 5);
      }
    } else if (xs) {
      if (pageIndex > 3) {
        setPageIndex(pageIndex - 4);
      }
    } else {
      if (pageIndex > 2) {
        setPageIndex(pageIndex - 3);
      }
    }
  };

  const setData = (description, patientName, tabName, phoneNumber) => {
    setDescriptionDialog(true);
    setDescription(description || '');
    setPatientName(patientName || '');
    setTabName(tabName || '');
    setPhoneNumber(phoneNumber || '');
  };

  useEffect(() => {
    if (currentUser?.doctor_id) {
      getTabs();
    }
  }, [tabs.length, currentUser]);

  const submit = async (data) => {
    try {
      setPressed(true);
      localStorage.setItem('scheduleData', JSON.stringify(data.schedules));
      const schedules = data.schedules.map((schedule) => ({
        startHour: schedule.startHour,
        endHour: schedule.endHour,
        interval: schedule.interval,
        off: schedule.off,
      }));

      const payload = {
        schedules,
        tab_id: tabs?.[tab]?.tab_id,
        patient_name: '',
        booking_status: false,
      };

      const response = await request('add-weekly-schedule', 'POST', payload);

      if (response.success) {
        console.log(response.message);
        setScheduleDialog(false);
        if (!JSON.parse(localStorage.getItem('scheduleData')))
          setValue(
            'schedules',
            days.map(() => ({
              startHour: '',
              endHour: '',
              interval: 15,
              off: false,
            }))
          );
        setReRender(!reRender);
      } else {
        console.error('Failed to create appointments:', response.error);
      }
    } catch (error) {
      console.error('An error occurred while creating appointments:', error);
    }
    setPressed(false);
  };

  const deleteUnbookedAppointments = async () => {
    const response = await request(
      `delete-unbooked-appointments/${tabs?.[tab]?.tab_id}`,
      'DELETE'
    );
    if (response.success) {
      setReRender(!reRender);
    }
  };

  return (
    <>
      <DeleteConfirmation
        open={deleteDialog}
        setOpen={setDeleteDialog}
        onClick={() => {
          deleteUnbookedAppointments();
          setScheduleMode(!scheduleMode);
          localStorage.setItem('scheduleMode', JSON.stringify(!scheduleMode));
        }}
        text={`Switching to ${
          scheduleMode ? 'Customize Mode' : 'Schedule Mode'
        } will permanently clear all appointments from the system. This cannot be undone!`}
        buttonLabel='Switch'
      />
      <Dialog
        sx={{
          '.MuiDialog-paper': {
            backgroundColor: '#F7F7F7',
            borderRadius: 5,
          },
        }}
        open={descriptionDialog}
        onClose={() => {
          setDescriptionDialog(false);
        }}
      >
        <DialogTitle
          color={'#36454F'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          sx={{ borderBottom: 2, borderColor: '#B2BEB5' }}
        >
          <img src={Notes} alt='Notes' width={35} />
          <h4 style={{ margin: 0 }}>Notes</h4>
        </DialogTitle>
        <DialogContent
          sx={{
            marginTop: 2,
            borderBottom: 2,
            borderColor: '#B2BEB5',
            display: 'flex',
            flexDirection: 'column',
            color: '#808080',
            overflowWrap: 'break-word',
            paddingX: xs ? 6 : 4,
          }}
        >
          <Typography>Name: {patientName}</Typography>
          <Typography>Phone: {phoneNumber}</Typography>
          <Typography>Tab: {tabName}</Typography>
          <Typography>Notes: {description}</Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{
              backgroundColor: '#B491CB',
              boxShadow: 'none',
              '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
              fontWeight: 'bold',
            }}
            onClick={() => {
              setDescriptionDialog(false);
            }}
            color='success'
            variant='contained'
          >
            {t('Done')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          '.MuiDialog-paper': {
            maxWidth: '1200px !important',
            width: '1200px !important',
            backgroundColor: '#F7F7F7',
            borderRadius: 5,
          },
        }}
        open={scheduleDialog}
        onClose={() => {
          setScheduleDialog(false);
          if (!JSON.parse(localStorage.getItem('scheduleData')))
            setValue(
              'schedules',
              days.map(() => ({
                startHour: '',
                endHour: '',
                interval: 15,
                off: false,
              }))
            );
        }}
      >
        <DialogTitle
          color={'#36454F'}
          display={'flex'}
          justifyContent={'center'}
          sx={{ borderBottom: 2, borderColor: '#B2BEB5' }}
        >
          <h4 style={{ margin: 0 }}>Set Schedule</h4>
        </DialogTitle>
        <DialogContent
          sx={{
            marginTop: 2,
            borderBottom: 2,
            borderColor: '#B2BEB5',
            display: 'flex',
            flexDirection: 'column',
            color: '#36454F',
            overflowWrap: 'break-word',
            paddingX: 2,
            overflowX: 'auto',
          }}
        >
          {fields?.map((field, index) => {
            const isDisabled = schedules[index]?.off;
            const defaultInterval = schedules[index]?.interval;

            return (
              <Grid
                key={field.id}
                display='flex'
                mb={1}
                alignItems='center'
                justifyContent={'space-between'}
                columnGap={1}
                container
                sx={{ minWidth: '800px' }}
              >
                <Grid item xs={2}>
                  <Typography>{days[index]}</Typography>
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>Start Time</Typography>
                  <TextField
                    sx={{
                      '&:focus-within fieldset': {
                        borderColor: 'black !important',
                      },
                    }}
                    size='small'
                    fullWidth
                    {...register(`schedules.${index}.startHour`)}
                    type='time'
                    margin='normal'
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>End Time</Typography>
                  <TextField
                    sx={{
                      '&:focus-within fieldset': {
                        borderColor: 'black !important',
                      },
                    }}
                    size='small'
                    fullWidth
                    {...register(`schedules.${index}.endHour`)}
                    type='time'
                    margin='normal'
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>Appointment Interval Time</Typography>
                  <FormControl margin='normal' fullWidth disabled={isDisabled}>
                    <Select
                      sx={{
                        '&:focus-within fieldset': {
                          borderColor: '#008000 !important',
                        },
                        '& fieldset': {
                          borderColor: '#008000 !important',
                        },
                      }}
                      size='small'
                      fullWidth
                      defaultValue={defaultInterval || 15}
                      {...register(`schedules.${index}.interval`)}
                    >
                      <MenuItem value={15} sx={{ color: 'white' }}>
                        15 minutes
                      </MenuItem>
                      <MenuItem value={30} sx={{ color: 'white' }}>
                        30 minutes
                      </MenuItem>
                      <MenuItem value={45} sx={{ color: 'white' }}>
                        45 minutes
                      </MenuItem>
                      <MenuItem value={60} sx={{ color: 'white' }}>
                        60 minutes
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControlLabel
                    sx={{
                      '.MuiButtonBase-root': { color: 'black !important' },
                    }}
                    control={
                      <Checkbox
                        {...register(`schedules.${index}.off`)}
                        checked={isDisabled}
                      />
                    }
                    label={'off'}
                  />
                </Grid>
              </Grid>
            );
          })}
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disableElevation
            onClick={() => {
              setScheduleDialog(false);
              if (!JSON.parse(localStorage.getItem('scheduleData')))
                setValue(
                  'schedules',
                  days.map(() => ({
                    startHour: '',
                    endHour: '',
                    interval: 15,
                    off: false,
                  }))
                );
            }}
            sx={{
              height: 40,
              width: 100,
              fontSize: 15,
              color: 'white',
              fontWeight: 'bold',
              borderRadius: 2,
              backgroundColor: '#B491CB',
              '&:hover': {
                backgroundColor: '#B491CB',
              },
            }}
            variant='contained'
          >
            Cancel
          </Button>
          <Button
            disableElevation
            disabled={pressed}
            onClick={handleSubmit(submit)}
            sx={{
              height: 40,
              width: 100,
              fontSize: 15,
              color: 'white',
              fontWeight: 'bold',
              border: '2px solid #838383',
              borderRadius: 2,
              marginInlineStart: 1,
              backgroundColor: '#008000',
              '&:hover': {
                backgroundColor: '#008000',
              },
            }}
            variant='contained'
          >
            {pressed ? <CircularProgress size={20} /> : 'Apply'}
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        backgroundColor={'background.paper'}
        color={'white'}
        display={'flex'}
        justifyContent={'center'}
        paddingY={5}
      >
        <h1>{t('Schedule & Bookings')}</h1>
      </Box>
      <Grid container dir={textDirection} paddingTop={1}>
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          borderBottom={1}
          paddingY={2}
          marginX={2}
        >
          <h2 style={{ paddingBottom: 20 }}>{t('New Bookings')}</h2>
          <Box
            width={'95%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            border={2}
            borderColor={'background.paper'}
            borderRadius={5}
            paddingX={1}
            marginBottom={2}
          >
            <h3
              style={{
                fontSize: xs ? 18 : 14,
                borderInlineEnd: '2px solid #B491CB',
                width: '100%',
                height: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {'Name'}
            </h3>
            <h3
              style={{
                fontSize: xs ? 18 : 14,
                borderInlineEnd: '2px solid #B491CB',
                width: '100%',
                height: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {'Phone'}
            </h3>
            <h3
              style={{
                fontSize: xs ? 18 : 14,
                borderInlineEnd: '2px solid #B491CB',
                width: '100%',
                height: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {'Date'}
            </h3>
            <h3
              style={{
                fontSize: xs ? 18 : 14,
                borderInlineEnd: '2px solid #B491CB',
                width: '100%',
                height: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {'Time'}
            </h3>
            <h3
              style={{
                fontSize: xs ? 18 : 14,
                borderInlineEnd: xs && '2px solid #B491CB',
                width: '100%',
                height: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
                marginInlineEnd: xs ? 40 : 5,
              }}
            >
              {'Tab'}
            </h3>
          </Box>
          {bookings?.map((booking, index) => (
            <DoctorBookingCard
              key={index}
              tab={
                tabs?.filter((tab) => tab?.tab_id === booking?.tab_id)?.[0]
                  ?.tab_name
              }
              description={booking?.description}
              getBookings={getBookings}
              appointment_id={booking?.appointment_id}
              booking_id={booking?.booking_id}
              time={booking?.booking_start_time}
              date={booking?.booking_date}
              patient_name={
                (booking?.first_name || '') + ' ' + (booking?.last_name || '')
              }
              patient_age={booking?.age || booking?.phone_number}
            />
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'column'}
          paddingTop={2}
          paddingBottom={5}
        >
          <Box
            display={'flex'}
            flexDirection={sm ? 'row' : 'column'}
            alignItems={'center'}
            justifyContent={'space-between'}
            rowGap={4}
            paddingX={sm ? 10 : 0}
          >
            <h1 style={{ fontSize: !sm && 20 }}>{t('Schedule')}</h1>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        color: '#b270dd',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#b270dd',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: '#b270dd',
                        },
                    }}
                    checked={scheduleMode}
                    onChange={() => {
                      setDeleteDialog(true);
                    }}
                  />
                }
                label={scheduleMode ? 'Schedule Mode' : 'Customize Mode'}
              />
            </FormGroup>
            {scheduleMode && (
              <Button
                sx={{
                  backgroundColor: '#B491CB',
                  boxShadow: 'none',
                  color: 'white',
                  padding: 2,
                  '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
                  fontSize: 16,
                }}
                variant='contained'
                onClick={() => {
                  setScheduleDialog(true);
                }}
              >
                Set Schedule
              </Button>
            )}
            <TextField
              defaultValue={new Date().toISOString().split('T')[0]}
              type='date'
              onChange={(e) => setCustomDate(e.target.value)}
            />
            <FormControl sx={{ width: sm ? 200 : 150 }}>
              <InputLabel
                style={{ color: 'black' }}
                id='demo-simple-select-label'
              >
                Tab
              </InputLabel>
              <Select
                color='secondary'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={tab}
                label='Tab'
                onChange={(tab) => {
                  setTab(tab.target.value);
                }}
              >
                {tabs?.map((tab, index) => (
                  <MenuItem sx={{ color: 'white' }} key={index} value={index}>
                    {tab?.tab_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            marginTop={2}
            sx={{ paddingInlineStart: xs ? (pageIndex > 2 ? 0 : 5.4) : 0 }}
          >
            {pageIndex > 2 && xs && (
              <IconButton style={{ height: 50 }} onClick={prev}>
                {<h1>&lt;</h1>}
              </IconButton>
            )}
            {tabs?.length ? (
              <ScheduleTable
                reRender={reRender}
                doctor_id={currentUser?.doctor_id}
                pageIndex={pageIndex}
                tabIndex={tab}
                tabs={tabs}
                bookDialog={bookDialog}
                setBookDialog={setBookDialog}
                setData={setData}
                scheduleMode={scheduleMode}
                customDate={customDate}
              />
            ) : (
              <CircularProgress />
            )}
            {xs && (
              <IconButton style={{ height: 50 }} onClick={next}>
                {<h1>&gt;</h1>}
              </IconButton>
            )}
          </Box>
          {!xs && (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-evenly'}
              height={60}
            >
              {pageIndex > 2 && (
                <IconButton onClick={prev}>{<h1>&lt;</h1>}</IconButton>
              )}
              <IconButton onClick={next}>{<h1>&gt;</h1>}</IconButton>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorBookings;
